<template>
  <footer class="footer" id="footer">
    <div class="footer-main">
      <div class="container">
        <div class="inner-wrapper">
          <ul>
            <li><a href="">{{translations['terms']}}</a></li>
            <li><a href="">{{translations['privacy']}}</a></li>
            <li><a href="">{{translations['cookies']}}</a></li>
          </ul>
          <div class="contacts">
            {{translations['hot_line']}} +374 60 54 44 44
          </div>
        </div>
      </div>
    </div>
    <div class="footer-sponsors">
      <div class="container">
        <div class="inner-wrapper sponsors-wrapper">
          <div class="logos">
            <div class="logos-inner">
              <div class="logo">
                <img src="@/assets/images/sponsors/British-Embassy.png" alt="[British Embassy]"
                     srcset="@/assets/images/sponsors/British-Embassy@2x.png"/>
              </div>
              <div class="logo">
                <img src="@/assets/images/sponsors/Good-governance.png" alt="[Good Governance]"
                     srcset="@/assets/images/sponsors/Good-governance@2x.png"/>
              </div>
            </div>
            <div class="logos-inner">
              <div class="logo">
                <img src="@/assets/images/sponsors/Reform-assistance.png" alt="[Reform Assistance]"
                     srcset="@/assets/images/sponsors/Reform-assistance@2x.png"/>
              </div>
              <div class="logo">
                <img src="@/assets/images/sponsors/UKaid.png" alt="[UK Aid]"
                     srcset="@/assets/images/sponsors/UKaid@2x.png"/>
              </div>
            </div>
          </div>
          <div class="sponsorship-description">
            <div class="en">
              This material has been funded by UK aid from the UK government; however the views
              expressed do not necessarily reflect the UK government’s official policies.
            </div>
            <div class="hy">
              Նյութը պատրաստվել է Մեծ Բրիտանիայի և Հյուսիսային Իռլանդիայի Միացյալ Թագավորության
              կառավարության ֆինանսավորմամբ։ Արտահայտված տեսակետները պարտադիր չէ, որ արտացոլեն
              Միացյալ Թագավորության կառավարության պաշտոնական դիրքորոշումը։
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  computed: {
    translations() {
      return this.$store.getters.getTranslations;
    },
  },
};
</script>

<style scoped lang="scss">
  @import "@/assets/styles/layout/Footer.scss";
</style>
